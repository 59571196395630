export let colors = ["rgb(255, 0, 0)", "rgb(0, 102, 255)"];

export const info = {
	socials: [
        {
            link: "https://github.com/mikethesilb",
            icon: "github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/michael-silber/",
            icon: "linkedin",
            label: 'linkedin'
        },
        {
            link: "https://discord.com/users/mikethesilb",
            icon: "discord",
            label: 'discord'
        },
        {
            link: "mailto:mikesilber94@gmail.com",
            icon: "google",
            label: 'gmail'
        }
    ],
    miniBio: [
        {
            emoji: '👨🏻‍💻',
            text: 'Self Learner'
        },
        {
            emoji: '🌎',
            text: 'Internationally Minded'
        },
        {
            emoji: "🚀",
            text: "Research and Theory Oriented"
        },
        {
            emoji: "🏉",
            text: "Spirited Rugby Player"
        },
        {
            emoji: "🧠",
            text: "Keen Practitioner of Mindfulness"
        }
    ],
    experience: {
        backend: [
            {
                skill:'Python',
                level:'Experienced'
            },
            {
                skill:'Java',
                level:'Experienced'
            },
            {
                skill:'AWS',
                level:'Experienced'
            },
            {
                skill:'OpenAI',
                level:'Experienced'
            },
            {
                skill:'LangChain',
                level:'Experienced'
            },
            {
                skill:'Pinecone',
                level:'Experienced'
            },
            {
                skill:'JavaScript',
                level:'Experienced'
            },
            {
                skill:'SpringBoot',
                level:'Experienced'
            },
        	{
        		skill:'SQL',
        		level:'Experienced'
        	},
            {
                skill:'SQLAlchemy',
                level:'Intermediate'
            },
            {
                skill:'FastAPI',
                level:'Intermediate'
            },
            {
                skill:'C',
                level:'Intermediate'
            },
            {
                skill:'C++',
                level:'Intermediate'
            }
        ],
        data: [
            {
                skill:'NumPy',
                level:'Experienced'
            },
            {
                skill:'Pandas',
                level:'Experienced'
            },
            {
                skill:'SciKit',
                level:'Experienced'
            },
            {
                skill:'OpenCV',
                level:'Experienced'
            },
            {
                skill:'TensorFlow',
                level:'Intermediate'
            },
            {
                skill:'PyTorch',
                level:'Intermediate'
            },
        ],
        other: [
            {
                skill:'Git',
                level:'Experienced'
            },
            {
                skill:'NPM',
                level:'Experienced'
            },
            {
                skill:'Terraform / Cloudformation',
                level:'Experienced'
            },
            {
                skill:'Linux',
                level:'Experienced'
            },
            {
                skill:'React',
                level:'Intermediate'
            }
        ],
    },
    resume: [
            {
                text: 'Fullstack Team Lead @ bio-T',
                date: 'Jan 2022 - Jan 2023',
                description: 'During my time at bio-T, I was promoted to the position of Team Lead for the Neteera (<a href="https://neteera.com/" target="_blank">https://neteera.com/</a>) IoT solution midway through the project. As a Team Lead, I ensured the efficient development and timely delivery of the project by effectively managing and coordinating tasks. Collaborating with the senior developer, I conducted meticulous code reviews to maintain code quality and uphold best practices. I actively fostered collaborative decision-making during critical junctures through engaging brainstorming sessions with the whole team. My role also involved providing regular progress updates to both bio-T and Neteera management, showcasing my strong communication and risk management abilities. Acting as an IoT expert, I guided customers in designing tailored systemic solutions that precisely addressed their unique needs. Additionally, I adeptly handled customer requests and expectations, including onerous demands on a tight schedule, while personally resolving critical bugs within tight timelines due to concurrent clinical trials during the development phase.',
                category: {
                    tag: 'teamlead-biot'
                },
                url: 'https://www.biot-med.com/'
            },
            {
                text: 'Fullstack Engineer @ bio-T',
                date: 'Mar 2021 - Jan 2022',
                description: 'Worked in a collaborative team of full-stack developers tasked with dilvering a full web solution for Neteera (<a href="https://neteera.com/" target="_blank">https://neteera.com/</a>), leveraging Java 15 and SpringBoot, deployed on AWS ECS Fargate for a robust back-end micro-service architecture. We created a secure and efficient IoT solution for live monitoring and management of multiple devices, leveraging AWS IoT and Timestream. We developed RESTful APIs and utilized AWS Timestream to seamlessly store and retrieve patient monitoring data. Furthermore, we designed and built a user-friendly front-end using React, Redux, and MaterialUI. Additionally, as a team, we implemented statistic APIs using AWS Timestream, providing doctors with valuable insights into patient vital signs monitoring history. The result was a web application that allowed doctors and nurses to remotely monitor patients in hospital and geriatric wards.',
                category: {
                    tag: 'fullstack-biot'
                },
                url: 'https://www.biot-med.com/'
            },
            {
                text: 'Junior Back-end Developer @ bio-T',
                date: 'Aug 2019 - Mar 2021',
                description: 'As a junior backend engineer, I\'ve been actively involved in various projects that have contributed to enhancing system efficiency. Notably, I developed a fully automated system using AWS Lambdas to optimize storage space in the ElasticSearch domain, streamlining operations by eliminating the need for human intervention. Additionally, I successfully implemented a scalable manager-worker pattern for an existing ETL service, leveraging Spring Boot and Amazon SQS to improve concurrency and system performance. Moreover, I optimized the ETL service\'s data transfer process by developing an efficient multithreaded mechanism for buffering directories through FTP. These achievements reflect my commitment to delivering reliable and innovative backend solutions.',
                category: {
                    tag: 'education'
                },
                url: 'https://www.biot-med.com/'
            },
            {
                text: 'Consultant @ IAI Elta',
                date: 'Jul 2018 - Oct 2018',
                description: 'Had the opportunity to work on a collaborative project with the IDF and IAI Elta Systems Ltd as a summer internship from university, focusing on classified intelligence and neural networks. I contributed my military background in visual intelligence to support data analysis, parameterization, and the generation of tagged data for training purposes. This experience provided valuable insights into the intersection of intelligence and cutting-edge technology.',
                category: {
                    tag: 'elta'
                },
                url: 'https://www.iai.co.il/about/groups/elta-systems'
            },
            {
                text: 'BsC in Computer Science @ Ben Gurion University',
                date: 'Oct 2016 - Jan 2020',
                description: 'Major: Machine Learning and Big Data',
                category: {
                    tag: 'education'
                },
                url: 'https://in.bgu.ac.il/en/Pages/default.aspx'
            },
            {
                text: 'Non-Comissioned Intelligence Officer @ Israeli Air Force (IAF)',
                date: 'April 2013 - Oct 2016',
                description: 'Enlisted in the Israeli Air Force Intelligence Corps right after high school, where my service unfolded in three remarkable phases. In the initial year, I immersed myself in specialized training, embracing the intensity of a dynamic, high-stakes, and time-sensitive environment. During my second year, I had the honor of commanding and educating fellow soldiers in my profession, gaining invaluable insights into the art of teaching and distilling complex technical knowledge into accessible lessons. Finally, in the last year of my service, I was offered to stay overtime for another half a year while taking on an operational position involving multi-disciplinary research, surgical decision making, and the opportunity to meet and present results to the highest-ranking officials in the Air Force, including the Air Force commander and the current IDF Chief of Staff, who was then the Vice Chief of Staff.',
                category: {
                    tag: 'idf'
                },
                url: 'https://www.iaf.org.il/9202-en/IAF.aspx'
            }
    ],
    projects: [
        {
            title: "Books to Knowledge",
            link: "https://github.com/mikethesilb/llm-book-to-knowledge",
            description: 'Utilized LangChain framework with LLMs to distill key points from "The Muscle and Strength Training Pyramid v2.0" book by Eric Helms. Ongoing exploration into LLMs\' ability to extract actionable insights in physiology.',
            tags: ["llm", "langchain", "pinecone", "python"]
        },
        {
            title: "Entrepeneurship: Gamz",
            link: "",
            description: "Created a robust sports venue web-app during the 2022 FIFA World Cup, onboarded 1000+ users & 20-30 venues in Tel Aviv. Challenges emerged post-event ultimately leading to the discontinuation of the project.",
            tags: ["NodeJS", "React", "JS", "Entrepeneurship", "Web-app"]
        },
        {
            title: "PAC Learning Shuffle Ideals",
            link: "https://github.com/mikethesilb/PAC-Learning-Shuffle-Ideals/tree/master",
            description: "Implemented python code to learn a sub-class of regular languages, based on a theoretical model from a research paper. Applications in NLP, computational linguistics & biology, data-mining, and cryptography.",
            tags: ["python", "machine-learning", "research", "automata"]
        },
        {
            title: "Movie Clustering",
            link: "https://github.com/mikethesilb/K-MEANS-Movie-Clustering",
            description: "Vectorized movies in the University of Minnesota's MovieLens dataset and implemented a Java-based k-means clustering with a heuristic function for personalized movie recommendations.",
            tags: ["k-means", "machine-learning", "java"]
        },
        {
            title: "Trigrams Forecasting",
            link: "https://github.com/mikethesilb/TrigramsE-M",
            description: "Developed a map-reduce implementation in Apache Hadoop (AWS EMR) to calculate Hebrew trigram probabilities based on a linguistic theoretical model, using the Google Hebrew Trigram dataset as the corpus.",
            tags: ["AWS", "NLP", "machine-learning", "hadoop", "map-reduce"]
        }
    ],
    firstName: "Mike",
    lastName: "Silber",
    initials: "MS", 
    position: "Software Engineer and Data Scientist",
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
}