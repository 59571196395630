import Canvas from './background/canvas.js'
import Navbar from './components/Navbar.js'
import About from './pages/About.js'
import Resume from './pages/Resume.js'
import Portfolio from './pages/Portfolio.js'
import './App.css';
import { Route, Switch, HashRouter } from "react-router-dom";
import {Box, Grid} from "@mui/material";


import SocialIcon from "./components/SocialIcon.js"

function App() {

  let Component
  switch (window.location.pathname){
    case "/":
      Component = About
      break
    case "/resume":
      Component = Resume
      break
    case "/portfolio":
      Component = Portfolio
      break
  }

  return (
    <div style={{'display':'flex', 'flexDirection':'column', 'height':'100vh'}}>
      <script src="https://kit.fontawesome.com/5f44bbffb2.js" crossOrigin="anonymous"></script>
      <Canvas id="gol" />
      <Navbar />
      <div style={{'display':'flex', 'flex': '1', 'flexDirection':'column', 'height':'100vh'}}>
        <Component />
        <div style={{'flex':'1'}}></div>
        <Grid item>
          <Box component={'footer'} display={'flex'} flexDirection={'row'} alignItems={'center'}
                py={'1.5rem'} sx={{opacity: 0.7}} width={'100%'}>
              <p style={{'flex': '2'}}></p>
              <p style={{'color':'white', 'fontSize':'x-small'}}>Michael Silber 2023</p>
              <p style={{'flex':'1'}}></p>
              <p style={{'color':'white', 'fontSize':'x-small'}}>Logo Art Created by Evan MacDonald from Noun Project</p>
              <p style={{'flex': '2'}}></p>
          </Box>
        </Grid>   
      </div> 
    </div>
  );
}


export default App;
