import me from "../assets/profile-pic.png"
import {Box} from "@mui/material";
import SocialIcon from "../components/SocialIcon";
import {info} from "../info.js"
import classNames from 'classnames';
import EmojiBullet from "../components/EmojiBullet"
import Style from './About.scss';


export default function About() {

	return (
		<div>	
	      	<Box component={'main'} display={'flex'} flexDirection={{xs: 'column', md: 'row'}} alignItems={'center'}
	           justifyContent={'center'} minHeight={'calc(100vh - 175px)'} marginTop={'10px'} >


	        	<Box className={classNames("avatar", "shadowed")} alt={'image of developer'} style={{background: info.gradient}} component={'img'} src={me} width={{xs: '25vh', md: '33vw'}}
	              height={{xs: '25vh', md: '66vh'}}
	              borderRadius={'50%'} p={'0.75rem'} mb={{xs: '1rem', sm: 0}} mr={{xs: 0, md: '2rem'}}/>

	         	<Box>


	            <h1>Hi, I'm <span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>{info.firstName}</span></h1>
	            <h2>{info.position}</h2>
	            <Box component={'h1'} p={'0rem'} fontSize={'1rem'}>
	               Curious Scientist in Mind, Passionate Entrepeneur at Heart
	            </Box>
	            <Box component={'h1'} p={'0rem'} fontSize={'1rem'}>
	               Former Fullstack Team Lead
	            </Box>
				<Box component={'h1'} p={'0rem'} fontSize={'1rem'}>
	               Currently Exploring LLM Projects
	            </Box>
	            <Box component={'h1'} p={'0rem'}>
	               {info.miniBio.map((bio, index) => (
	                  <EmojiBullet key={index} emoji={bio.emoji} text={bio.text}/>
	               ))}
	            </Box>
	         </Box>

      		</Box>
               <Box display={'flex'} gap={'2rem'} justifyContent={'center'} fontSize={{xs: '2rem', md: '2.5rem'}}>
               {info.socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box>
    	</div>
	);
}

