import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import {Box} from "@mui/material";
import {info} from "../info.js"
import {BsPatchCheckFill} from 'react-icons/bs'
import "./Timeline.css"
import "./Resume.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IM1 from '../assets/job_icons/biot-logo.jpeg'
import IM2 from '../assets/job_icons/biot-logo.jpeg'
import IM3 from '../assets/job_icons/biot-logo.jpeg'
import IM4 from '../assets/job_icons/iai-logo.jpeg'
import IM5 from '../assets/job_icons/bgu-logo.png'
import IM6 from '../assets/job_icons/iaf-logo.webp'
import IM7 from '../assets/job_icons/bgu-logo.png'
import IM8 from '../assets/job_icons/bgu-logo.png'


const THUMBS = [IM1, IM2, IM3, IM4, IM5, IM6, IM7, IM8];

const urls = []

const Timeline = () => (
<div className="timeline"> 

	{info.resume.map((data,idx) => (
	  <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
	    <div className="timeline__event__icon ">
			<a href={data.url} target="_blank">
	    <img src={THUMBS[idx]} className="timeline__event__pic" />
		</a>
	    </div>
	    <div className="timeline__event__date">
	      {data.date}
	    </div>
	    <div className="timeline__event__content ">
	      <div className="timeline__event__title">
	        {data.text}
	      </div>
	      <div className="timeline__event__description">
		  {data.category.tag==='fullstack-biot' || data.category.tag==='teamlead-biot' ?
		    <p dangerouslySetInnerHTML={{ __html:data.description}}></p> :
	      	<p> {data.description} </p>}
	      </div>
	    </div>
	  </div>
	))}
</div>
);

const Experience = () => (
	<section id='experience'>
		<div className="container experience_container">
			<div>
				<h3> Backend </h3>
				<div className="experience_content">
					{info.experience.backend.map((data,idx) => (
						<article className='experience_details' key={idx}>
							<BsPatchCheckFill  className='experience_details-icon'/>
							<div>
								<h4>{data.skill}</h4>
								<h5>{data.level}</h5>
							</div>
						</article>
						))}
				</div>
			</div>
			<div>
				<h3> Data </h3>
				<div className="experience_content">
					{info.experience.data.map((data,idx) => (
						<article className='experience_details' key={idx}>
							<BsPatchCheckFill  className='experience_details-icon'/>
							<div>
								<h4>{data.skill}</h4>
								<h5>{data.level}</h5>
							</div>
						</article>
						))}
				</div>
			</div>
			<div>
				<h3> Other </h3>
				<div className="experience_content">
					{info.experience.other.map((data,idx) => (
						<article className='experience_details' key={idx}>
							<BsPatchCheckFill  className='experience_details-icon'/>
							<div>
								<h4>{data.skill}</h4>
								<h5>{data.level}</h5>
							</div>
						</article>
						))}
				</div>
			</div>
		</div>
	</section>

);

const Download = () => (
	<div>

	</div>
);

export default function Resume() {
	return (
		<div>
					<div>
						<Experience />
						<Timeline />
						<Download />
					</div>
		</div>
	);
}