import "./Portfolio.css"
import {info} from "../info.js"
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IM1 from '../assets/thumbnails/llm-books.png'
import IM2 from '../assets/thumbnails/gamz-gray.png'
import IM3 from '../assets/thumbnails/k-means.png'
import IM4 from '../assets/thumbnails/pac-learning.png'
import IM5 from '../assets/thumbnails/trigrams.png'


library.add( faGithub );

const IMAGES = [IM1, IM2, IM3, IM4, IM5];

const Project = ({ data, k }) => (
	<div className='portfolio-project'>
		<h4 className='portfolio-title'> {data.title} </h4>

		{data.link.length > 0 &&
			<div className='github-icon'> 
				<GITHUB link={data.link}/>

			</div>
		}

		<img className='portfolio-img' src={IMAGES[k]}/>
		<div className='portfolio-text'> {data.description} </div>

		<div className='portfolio-tags'> {data.tags.join(" ")} </div>


	</div>
);

const GITHUB = ({link}) => (
    <a target="_blank" aria-label={"github"} rel="noopener noreferrer" href={link}>
		<FontAwesomeIcon icon={["fab", "github"]} color="black"/>
    </a>
)



export default function Portfolio() {
	return (
	<div className='portfolio-container'>
	    {info.projects.map((data, idx) => (
	        <Project data={data} k={idx} key={idx} />
	    ))}
	</div>
	)
}